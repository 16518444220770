<template>
  <div>
    <EditDialog
      width="80%"
      :isReturn="true"
      @closeFn="$emit('update:isShow', false)"
      :isShow.sync="invoiceIsShow"
    >
      <div slot="title" class="title">确认开票</div>
      <div slot="content" class="content" v-loading="loading" max-width="400">
        <el-form :model="form" ref="form" class="invoice-form" :rules="rulesInvoice">
          <div class="invoice-form-top">
            <el-form-item style="width: 300px" prop="invoicingNum" ref="invoicingNum">
              <span style="color: red">*</span> 发票编号：<el-input
                class="input_width"
                v-model.trim="form.invoicingNum"
              ></el-input>
            </el-form-item>
            <el-form-item style="width: 240px" prop="invoiceDate" ref="invoiceDate">
              <span style="color: red">*</span>开票日期：
              <el-date-picker
                :clearable="false"
                style="width: 140px"
                v-model="form.invoiceDate"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item style="width: 45%">
              <div class="invoice-remark">
                <span style="white-space: nowrap">备注：</span>
                <el-input
                  type="textarea"
                  autosize
                  placeholder="请输入备注内容"
                  v-model="form.remark"
                >
                </el-input></div
            ></el-form-item>
          </div>
          <div style="padding-left: 10px">
            <div class="invoice-upload">
              <span style="color: red">*</span> 附件：
              <Upload
                class="invoice-file"
                :type="type"
                :fields="fields"
                @upload="invoiceUpload"
              ></Upload>
            </div>
            <div>
              <span
                v-for="(item, index) in form.affirmCostFileList"
                :key="index"
                class="invoice-file-text"
              >
                <a
                  v-if="isShowType(item)"
                  class="fj-text"
                  target="_blank"
                  style="text-decoration: revert; color: #409eff; padding-right: 8px"
                  :href="`${filepath}${item.filePath}`"
                >
                  {{ item.fileName }}
                </a>

                <span v-else class="img_txt" @click="imgIsShow(item, index)">{{
                  item.fileName
                }}</span>

                <i @click="invoiceDel(index)" class="el-icon-circle-close icon-delete"></i>
              </span>
            </div>
          </div>
          <el-table ref="multipleTable" border :data="form.invoiceApplicationDetailList">
            <el-table-column
              prop="numberNo"
              :show-overflow-tooltip="false"
              label="期数"
              align="center"
              width="80"
            >
              <template slot-scope="scope"> 第 {{ scope.row.numberNo }} 期 </template>
            </el-table-column>
            <el-table-column
              prop="stageName"
              :show-overflow-tooltip="false"
              label="阶段名称"
              min-width="200"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="invoiceAmount"
              :show-overflow-tooltip="false"
              label="金额"
              min-width="110"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.invoiceAmount | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="vatType"
              :show-overflow-tooltip="false"
              label="增值税类型"
              min-width="300"
              align="center"
            >
              <template slot="header" slot-scope="scope">
                <span style="color: red">*</span> <span>增值税类型</span></template
              >
              <template slot-scope="scope">
                <el-form-item
                  ref="vatType"
                  style="width: 100%"
                  :prop="'invoiceApplicationDetailList.' + scope.$index + '.vatType'"
                  :rules="rulesInvoice.vatType"
                >
                  <el-select
                    :disabled="false"
                    v-model="scope.row.vatType"
                    @change="rowChangeType(scope.row)"
                    placeholder="请选择增值税类型"
                    class="ipt_width"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in zzsList"
                      :key="item.id"
                      :label="item.vatName"
                      :value="item.vatType"
                    >
                    </el-option> </el-select
                ></el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              prop="VatData"
              :show-overflow-tooltip="false"
              label="开票内容"
              min-width="300"
              align="center"
            >
              <template slot="header" slot-scope="scope">
                <span style="color: red">*</span> <span>开票内容</span></template
              >
              <template slot-scope="scope">
                <el-form-item
                  ref="VatData"
                  style="width: 100%"
                  :prop="'invoiceApplicationDetailList.' + scope.$index + '.vatData'"
                  :rules="rulesInvoice.vatData"
                >
                  <VatDictionary
                    style="width: 100%"
                    valType="Data"
                    :disabled="false"
                    v-model="scope.row.vatData"
                    :clearable="false"
                    :code="scope.row.vatType"
                    @change="vatDataChange(scope.row)"
                    placeholder="请选择开票内容"
                /></el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              prop="vatRate"
              :show-overflow-tooltip="false"
              label="增值税税率"
              min-width="140"
              align="center"
            >
              <template slot-scope="scope">
                <VatDictionary
                  style="width: 100%"
                  valType="TaxRate"
                  :disabled="false"
                  v-model="scope.row.vatRate"
                  :code="scope.row.vatData"
                  :clearable="false"
                  @optionsChange="rowChangeRate($event, scope.row)"
                  @change="vatRateChange(scope.row)"
                  placeholder="请选择增值税税率"
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="vatFee"
              :show-overflow-tooltip="false"
              label="增值税"
              min-width="90"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.vatFee | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="additionalTaxFee"
              :show-overflow-tooltip="false"
              label="附加税"
              min-width="90"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.additionalTaxFee | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="stampTaxFee"
              :show-overflow-tooltip="false"
              label="印花税"
              min-width="90"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.stampTaxFee | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="taxesFee"
              :show-overflow-tooltip="false"
              min-width="110"
              label="总税费"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.taxesFee | applyAmount }}
              </template>
            </el-table-column>
          </el-table>
          <el-descriptions title="" :column="2" border>
            <el-descriptions-item label="合计" labelStyle="width:10%">
              {{ amount | applyAmount }}
            </el-descriptions-item>
            <el-descriptions-item label="金额大写" labelStyle="width:10%">
              {{ toChinese }}
            </el-descriptions-item>
          </el-descriptions>
        </el-form>
      </div>
      <div slot="footer" class="footer">
        <el-button type="success" @click="invoiceSubmit">保存</el-button>
        <el-button type="info" plain @click="$emit('update:isShow', false)">返回</el-button>
      </div>
    </EditDialog>

    <DialogImg
      v-if="dialogVisible"
      :dialogVisible.sync="dialogVisible"
      :filepath="filepath"
      :imgList="imgList"
      :img_index="img_index"
    ></DialogImg>
  </div>
</template>

<script>
import { changeMoneyToChinese } from '@/util/jsencrypt.js'
import { formLocationError } from '@/util/common'
export default {
  components: {
    Dictionary: () => import('@/components/Dictionary.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Upload: () => import('@/components/upload/Upload.vue'),
    VatDictionary: () => import('@/components/VatDictionary.vue'),
    DialogImg: () => import('@/components/DialogImg.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      loading: false,
      invoiceIsShow: false,
      //文件上传
      filepath: process.env.VUE_APP_FILEPATH,
      fields: {
        name: 'fileName',
        path: 'filePath',
        type: 'fileType',
      },
      type: 'FPSQFJ',
      form: {
        affirmCostFileList: [],
        invoiceApplicationDetailList: [],
        invoicingNum: null,
        invoiceDate: null,
        remark: null,
        id: null,
      },
      dictData: {
        VatData: [],
      },
      zzsList: [], //增值税类型
      rulesInvoice: {
        invoicingNum: [
          {
            required: true,
            message: '请输入发票编号',
            trigger: ['blur', 'change'],
          },
        ],
        invoiceDate: [
          {
            required: true,
            message: '请选择开票日期',
            trigger: ['blur', 'change'],
          },
        ],
        vatType: [
          {
            required: true,
            message: '请选择增值税类型',
            trigger: ['blur', 'change'],
          },
        ],
        vatData: [
          {
            required: true,
            message: '请选择开票内容',
            trigger: ['blur', 'change'],
          },
        ],
      },
      dialogVisible: false, //图片弹窗
      imgList: [],
      img_index: null,
    }
  },
  computed: {
    amount() {
      let num = 0
      if (this.form.invoiceApplicationDetailList) {
        this.form.invoiceApplicationDetailList.forEach(v => {
          num += Number(v.invoiceAmount)
        })
      }
      return num
    },
    toChinese() {
      let str = ''
      str = changeMoneyToChinese(this.amount)
      return str
    },
  },
  watch: {
    isShow: {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.getData()
        }
        this.invoiceIsShow = val
      },
    },
  },
  created() {},
  mounted() {},
  methods: {
    // 附件删除
    invoiceDel(i) {
      this.form.affirmCostFileList.splice(i, 1)
      this.imgList.splice(i, 1)
    },
    // 附件上传
    invoiceUpload(r) {
      r.forEach(v => {
        if (v) {
          v.type = this.type
          v.temporaryId = Date.now()
          if (!this.isShowType(v)) {
            this.imgList.push(v)
          }
        }
      })
      this.form.affirmCostFileList.push(...r)
    },
    // 开票内容变更
    vatDataChange(row) {
      if (row) {
        row.vatFee = null
        row.additionalTaxFee = null
        row.stampTaxFee = null
        row.taxesFee = null
      }
    },
    // 明细选择类型
    rowChangeType(row) {
      if (row) {
        row.vatData = null
        row.vatRate = null
        row.vatFee = null
        row.additionalTaxFee = null
        row.stampTaxFee = null
        row.taxesFee = null
      }
    },
    // 明细选择税率
    rowChangeRate(v, row) {
      if (v[0]) {
        row.vatRate = Number(v[0].vatType)
        //增值税
        row.vatFee = (
          (((Number(row.invoiceAmount) * 10000) / (1000000 + row.vatRate * 10000)) *
            (row.vatRate * 10000)) /
          10000
        ).toFixed(2)
        //附加税
        row.additionalTaxFee = (Number(row.vatFee) * 0.12).toFixed(2)
        //印花税
        row.stampTaxFee = (Number(row.invoiceAmount) * 0.0003).toFixed(2)
        //总税费
        row.taxesFee = Number(row.vatFee) + Number(row.additionalTaxFee) + Number(row.stampTaxFee)
      }
    },
    // 税率值变更
    vatRateChange(row) {
      if (row && row.vatRate) {
        //增值税
        row.vatFee = (
          (((Number(row.invoiceAmount) * 10000) / (1000000 + row.vatRate * 10000)) *
            (row.vatRate * 10000)) /
          10000
        ).toFixed(2)
        //附加税
        row.additionalTaxFee = (Number(row.vatFee) * 0.12).toFixed(2)
        //印花税
        row.stampTaxFee = (Number(row.invoiceAmount) * 0.0003).toFixed(2)
        //总税费
        row.taxesFee = Number(row.vatFee) + Number(row.additionalTaxFee) + Number(row.stampTaxFee)
      }
    },
    // 保存
    invoiceSubmit() {
      //  this.$api.invoice.financialInvoicingApply()
      this.$refs.form.validate((valid, object) => {
        if (valid) {
          if (this.form.affirmCostFileList.length <= 0) {
            this.$message.warning('请上传附件')
            return
          }

          this.loading = true
          this.$api.invoice
            .financialInvoicingApply(this.form)
            .then(res => {
              this.$message({
                message: '开票成功',
                type: 'success',
              })

              this.loading = false
              this.$emit('invoiceDetailsFn')
            })
            .catch(err => {
              console.log(err)
              this.loading = false
            })
        } else {
          if (this.$refs) {
            formLocationError(object, this.$refs)
            return false
          }
        }
      })
    },
    getData() {
      this.loading = true
      this.$api.invoice
        .selectDetailById(this.options.id)
        .then(res => {
          if (res.data) {
            this.form.id = res.data.id
            this.form.invoicingNum = res.data.invoicingNum
            this.form.invoiceDate = res.data.invoiceDate
            this.form.invoiceApplicationDetailList = res.data.invoiceApplicationDetailList
            this.form.invoiceApplicationDetailList.forEach(v => {
              v.vatType = res.data.vatType
              v.vatData = res.data.vatData
              v.vatRate = res.data.vatRate
            })
          }
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
      this.$api.vat
        .getVatTypeList()
        .then(res => {
          this.zzsList = res?.data?.records
        })
        .catch(err => {
          console.log(err)
        })
    },
    isShowType(n) {
      let arr = n.filePath.split('.')
      let str = arr[arr.length - 1]
      let srtArr = [
        'bmp',
        'dib',
        'pcp',
        'dif',
        'wmf',
        'gif',
        'jpg',
        'tif',
        'eps',
        'psd',
        'cdr',
        'iff',
        'tga',
        'pcd',
        'mpt',
        'png',
        'jpeg',
      ]
      if (srtArr.indexOf(str) == -1) {
        return true
      } else {
        return false
      }
    },
    imgIsShow(n, index) {
      this.img_index = 0
      this.imgList.forEach((v, i) => {
        if (v.temporaryId) {
          if (v.temporaryId === n.temporaryId) {
            this.img_index = i
          }
        }
      })
      this.dialogVisible = true
    },
  },
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return n
      }
    },
    transitionType(val, arr) {
      let str = ''
      if (arr && arr.length > 0 && val) {
        const item = arr.find(v => v.vatType == val)
        str = item?.vatName
      }
      return str
    },
    transitionData(val, arr) {
      let str = ''
      if (arr && arr.length > 0 && val) {
        const item = arr.find(v => v.vatData == val)
        str = item?.vatName
      }
      return str
    },
  },
}
</script>
<style scoped lang="scss">
.invoice-form {
  display: block !important;
  .input_width {
    width: 200px;
  }
  .invoice-form-top {
    display: flex;
  }
  .invoice-file {
    font-size: 26px;
    color: #399ffb;
  }
  .invoice-file-text {
    display: inline-block;
    width: 200px;
  }
  .icon-delete {
    font-size: 26px;
    color: red;
  }
  .invoice-upload {
    display: flex;
    align-items: center;
  }
  .invoice-remark {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .el-table {
    margin-top: 10px;
  }
}
.img_txt {
  text-decoration: underline;
  color: #409eff;
  &:hover {
    cursor: pointer;
  }
  .content {
    padding: 10px;
  }
}
</style>
